import { createContext } from 'react';

var _createContext = createContext({
  onInternalStateUpdate: function onInternalStateUpdate() {
    return undefined;
  },
  createHrefForState: function createHrefForState() {
    return '#';
  },
  onSearchForFacetValues: function onSearchForFacetValues() {
    return undefined;
  },
  onSearchStateChange: function onSearchStateChange() {
    return undefined;
  },
  onSearchParameters: function onSearchParameters() {
    return undefined;
  },
  store: {},
  widgetsManager: {},
  mainTargetedIndex: ''
}),
    InstantSearchConsumer = _createContext.Consumer,
    InstantSearchProvider = _createContext.Provider;

export { InstantSearchConsumer, InstantSearchProvider };

var _createContext2 = createContext(undefined),
    IndexConsumer = _createContext2.Consumer,
    IndexProvider = _createContext2.Provider;

export { IndexConsumer, IndexProvider };